<template>
  <div class="view-login auth mb-5">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-5 mx-auto">
          <h1 class="text-center mb-5">
            <img src="../assets/logo-light.png" />
          </h1>

          <div v-if="requestSucces" class="card">
            <div class="card-body p-5">
              <div class="checked-circle">
                <i class="icon-check" />
              </div>
              <h5 class="text-center mb-4 text-dark">Solicitação concluída!</h5>
              <p class="text-center text-secondary confirmation-font-size">
                Enviamos para
                <span class="font-weight-bold">{{ email }}</span> as
                instruções<br />
                para redefinir a senha de acesso à plataforma.
              </p>
              <div class="text-center pt-4 mb-4">
                <button
                  type="button"
                  class="btn btn-outline-success btn-block"
                  @click="backToLogin"
                >
                  Voltar para o login
                </button>
              </div>
            </div>
          </div>

          <form v-else class="card" @submit.prevent="submit">
            <div
              v-if="errors.email.length || errors.non_field_errors.length"
              class="card-head-invalid-feedback bg-danger text-white"
            >
              <i class="icon-alert-circle mr-2" />
              {{ errorMsg }}
            </div>
            <div class="card-body p-5">
              <h5 class="text-center mb-4">Esqueci minha senha</h5>
              <p class="text-center text-secondary mb-3">
                Digite o seu e-mail cadastrado para receber o link<br />
                com as instruções de redefinição de senha:
              </p>
              <div class="mb-4">
                <form-group
                  class="input-form-group"
                  v-model="email"
                  :isLogin="true"
                  id="email"
                  type="email"
                  label="E-MAIL"
                  placeholder="Digite seu e-mail"
                  :errors="errors.email"
                  :showErrorMessage="false"
                  @change="resetErrors"
                />
              </div>

              <div class="text-center pt-4 mb-4">
                <hollow-button
                  :loading="loading"
                  title="Enviar"
                  type="submit"
                  :disabled="!isValidFields"
                />
              </div>

              <p class="text-center mb-0">
                <button
                  type="button"
                  @click="backToLogin"
                  class="btn btn-sm button-forgot-password text-primary"
                >
                  Voltar para o login
                </button>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HollowButton from "@/components/HollowButton";
import get from "lodash/get";

export default {
  name: "login",
  components: {
    HollowButton
  },
  data() {
    return {
      email: "",
      errors: {
        email: [],
        non_field_errors: []
      },
      loading: false,
      requestSucces: false,
      isValidFields: false
    };
  },
  computed: {
    errorMsg() {
      return (
        get(this.errors, "email[0]") || get(this.errors, "non_field_errors[0]")
      );
    }
  },
  watch: {
    password() {
      this.validateFields();
    },
    email() {
      this.validateFields();
    }
  },
  methods: {
    validateFields() {
      this.isValidFields = !!this.email && /\S+@\S+\.\S+/.test(this.email);
    },
    resetErrors() {
      this.errors = {
        email: [],
        non_field_errors: []
      };
    },
    backToLogin() {
      this.$router.push({ name: "login" });
    },
    submit() {
      this.loading = true;
      this.resetErrors();

      this.$store
        .dispatch("user/passwordRecovery", {
          email: this.email
        })
        .then(() => {
          this.requestSucces = true;
          this.loading = false;
        })
        .catch(error => {
          const data = error.response.data;
          if (data.errors && Object.keys(data.errors).length) {
            this.errors.email = data.errors.email;
          } else {
            this.errors.non_field_errors = data.error_description;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.view-login {
  width: 100%;

  .input-form-group {
    font-weight: 500;
    font-size: 10px;
    color: #aeb6c4;
  }

  .button-forgot-password {
    font-weight: 900;
    font-size: 14px;
    text-align: center;
  }

  .card-head-invalid-feedback {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    font-size: 14px;
    border-radius: 16px 16px 0px 0px;
    position: absolute;
    top: -20px;
    padding: 4px 16px;
    text-align: center;
  }
}
</style>
